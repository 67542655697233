import React, { useRef } from "react";
import BackButton from "../BackButton/BackButton";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import { translations } from "../../utils/translations/translations";
import { directionVariants } from "../../utils/directionOptions";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import AppButton from "../AppButton/AppButton";
import {
  setRemoteHenchmens,
  setDirection,
  setRemoteData,
} from "../../redux/actions/actions";
import AnalyticsLogo from "../../images/ToBeAddedIcons/Analytics.svg";
import BillingLogo from "../../images/ToBeAddedIcons/BIlling.svg";
import TeamLogo from "../../images/ToBeAddedIcons/Team.svg";
import ShareLogo from "../../images/ToBeAddedIcons/Share.svg";
import BalanceLogo from "../../images/ToBeAddedIcons/Balance.svg";
import StarLogo from "../../images/ToBeAddedIcons/Star.svg";
import { useNavigate } from "react-router-dom";
import InviteLogo from "../../images/ToBeAddedIcons/Invite.svg";
import "./RemoteToBeAdded.css";

function RemoteToBeAdded() {
  const navigate = useNavigate();
  const direction = useSelector((state) => state.direction);
  const language = useSelector((state) => state.language);
  const currentUser = useSelector((state) => state.currentUser);
  return (
    <motion.section
      className={`remote-tobeadded ${currentUser.owner === 0 && "disabled"}`}
      initial={direction ? "fromLeft" : "fromRight"}
      animate={{
        x: 0,
        opacity: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: { duration: 0.2, delay: 0.2 },
      }}
      exit={direction ? "exitToRight" : "exitToLeft"}
      variants={directionVariants}
    >
      <div className={`remote__container whats_to_add_screen`}>
        <BackButton
          remote
          path="/remote"
          currentClass={`white`}
          title={translations[language].remote.whatIsPlanned}
        />
        <div className={`remote__users-block-top`}>
          <span className="remote__users-text">
            Аналитика: мощность серверов, удволетворенность&nbsp;пользователей,
            обратная связь от них
          </span>
          <img className="remote__future-icon" src={AnalyticsLogo} alt="robo" />
        </div>
        <div className="remote__blocks-row">
          <div className={`remote__half-block`}>
            <span className="remote__users-text-half">
              Удобное подключение новых сотрудников: через ссылку в
              корпоративном чате
            </span>
            <img className="remote__future-icon" src={ShareLogo} alt="robo" />
          </div>
          <div className={`remote__half-block balance-block`}>
            <span className="remote__users-text-half-title balance-text ">
              Баланс
            </span>
            <span className="remote__users-text-half balance-text ">
              Пополнение наперед
            </span>
            <img className="remote__future-icon" src={BalanceLogo} alt="robo" />
          </div>
        </div>
        <div className={`remote__users-block`}>
          <span className="remote__users-text">
            Билинг: история покупок и пополнений с возможность закрывающих
            документов
          </span>
          <img className="remote__future-icon" src={BillingLogo} alt="robo" />
        </div>
        <div className={`remote__users-block`}>
          <span className="remote__users-text">
            Доступ нескольким администраторам: сделаем разделение по командам и
            функционал для больших команд, более 20 человек, где можно так же
            будет добавить администраторов и дополнительные сервера
          </span>
          <img className="remote__future-icon" src={TeamLogo} alt="robo" />
        </div>
        <div className="remote__blocks-row">
          <div className={`remote__half-block-bottom-left balance-block`}>
            <span className="remote__users-text-half balance-text">
              Совместная оплата: оплата в складчину (Робо поможет собрать
              деньги)
            </span>
            <img className="remote__future-icon" src={StarLogo} alt="robo" />
          </div>
          <div className={`remote__half-block-bottom-right`}>
            <span className="remote__users-text-half">
              Реферальная программа: пригласи еще команду и получи месяц в
              подарок.
            </span>
            <img className="remote__future-icon" src={InviteLogo} alt="robo" />
          </div>
        </div>
      </div>
      <div className="remote__settings-button-future">
        <AppButton
          text={
            true
              ? translations.ru.remote.remoteNoOwnBtn
              : translations.ru.remote.remoteUseOpts
          }
          currentClass="app-button bg-white orange margin-top"
          handler={() => {
            setDirection(true);
            navigate("/tariffes/own");
          }}
        />
      </div>
    </motion.section>
  );
}

export default RemoteToBeAdded;
