import React, { useRef } from "react";
import BackButton from "../BackButton/BackButton";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import { translations } from "../../utils/translations/translations";
import { directionVariants } from "../../utils/directionOptions";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import AppButton from "../AppButton/AppButton";
import introImg from "../../images/intro_smile_0.svg";
import Popup from "../Popup/Popup";
import RemotePopup from "../RemotePopup/RemotePopup";
import {
  setRemoteHenchmens,
  setDirection,
  setRemoteData,
} from "../../redux/actions/actions";
import OrangeBg from "../../resources/images/orange.svg";
import OrangeTeamBg from "../../resources/images/orange-team.svg";
import OrangeSpecialBg from "../../resources/images/orange-special.svg";
import GreyBg from "../../resources/images/grey.svg";
import StarStruck from "../../resources/images/star-struck.png";
import Star from "../../resources/images/star.png";
import ArrowDown from "../../resources/images/arrow_down.svg";
import ArrowUp from "../../resources/images/arrow_up.svg";
import { setRemoteOpts, getHenchmenLink } from "../../utils/roboApi";
import { useNavigate } from "react-router-dom";
import "./Remote.css";
import "./RemoteBlock.css";
import { parseTimestamp } from "../../utils/helpers";

function Remote() {
  const navigate = useNavigate();
  const tg = window.Telegram.WebApp;
  const direction = useSelector((state) => state.direction);
  const remoteData = useSelector((state) => state.remoteData);
  const henchmens = useSelector((state) => state.remoteHenchmens);
  const currentUser = useSelector((state) => state.currentUser);
  const language = useSelector((state) => state.language);
  const [isRemotePopupHidden, setIsRemotePopupHidden] = React.useState(true);
  const [isRemoteUsersHidden, setIsRemoteUsersHidden] = React.useState(true);
  const [isSettingsChanged, setIsSettingsChanged] = React.useState(false);
  const [isTrafficLimited, setIsTrafficLimited] = React.useState(false);
  const [activeHenchmen, setActiveHenchmen] = React.useState(
    remoteData?.data?.henchmens[0]?.id
  );
  const [activeHenchmenIndex, setActiveHenchmenIndex] = React.useState(0);
  const [doneMessageShow, setDoneMessageShow] = React.useState(false);
  const [doneMessage, setDoneMessage] = React.useState();
  const [nameChanged, setNamechanged] = React.useState(
    remoteData?.data?.henchmens[activeHenchmenIndex]?.description
  );
  const [trafficChanged, setTrafficChanged] = React.useState(
    remoteData?.data?.henchmens[activeHenchmenIndex]?.trafficLimit
  );
  const isRemoteInProgress =
    currentUser.owner === 1 &&
    (!remoteData.data || remoteData?.data?.henchmens?.length === 0);
  const isDisabled = currentUser.owner === 0;
  const today = new Date();
  const isTested = 0;
  const isNeverOwn = isDisabled && currentUser.own_tested === 0;
  const isTestOwnTariff =
    currentUser.own_tested === 1 &&
    (remoteData?.data?.tariff === "ownme_test" ||
      remoteData?.data?.tariff === "ownfam_test" ||
      remoteData?.data?.tariff === "owncom_test");
  const isOwnTariff =
    currentUser.owner === 1 &&
    remoteData?.data?.tariff !== "ownme_test" &&
    remoteData?.data?.tariff !== "ownfam_test" &&
    remoteData?.data?.tariff !== "owncom_test";

  const totalHechmensTraffic = remoteData?.data?.hechmens?.reduce(
    (acc, hechman) => acc + hechman.trafficLimit,
    0
  );
  const tariffType = remoteData?.data?.tariff?.replace(/[^a-zA-Z]/g, "");
  const tariffMonths = remoteData?.data?.tariff?.replace(/[a-zA-Z_]/g, "");
  const getMonthOrTest =
    remoteData?.data?.tariff?.includes("_1") ||
    remoteData?.data?.tariff?.includes("_test");

  const isShowSpecialFieldsLayout = currentUser?.isTeamSpecialPromoActive;
  const [isSpecialOfferActive, setIsSpecialOfferActive] = React.useState(true);
  const [areDetailsVisible, setAreDetailsVisible] = React.useState([
    false,
    false,
  ]);
  const [isCopied, setIsCopied] = React.useState(false);

  const handlePressCopy = () => {
    if (isCopied) return;
    navigator.clipboard.writeText(currentUser.referralLink);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  const handlePressShare = () => {};

  const toggleDetailsVisibility = (index) => {
    const updatedVisibility = [...areDetailsVisible];
    updatedVisibility[index] = !updatedVisibility[index];
    setAreDetailsVisible(updatedVisibility);
  };

  const getOwnTariffTypeGb = () => {
    if (tariffType === "ownme") {
      return 500;
    }
    if (tariffType === "ownfam") {
      return 1000;
    }

    if (tariffType === "owncom") {
      return 2000;
    }
    if (remoteData?.data?.tariff?.includes("_test")) {
      return 50;
    }
  };

  const getOwnTariffPeople = () => {
    if (tariffType === "ownme" || tariffType === "ownmetest") {
      return "1-3";
    }
    if (tariffType === "ownfam" || tariffType === "ownfamtest") {
      return "4-6";
    }

    if (tariffType === "owncom" || tariffType === "owncomtest") {
      return "7-12";
    }
  };

  React.useEffect(() => {
    setTrafficChanged(
      remoteData?.data?.henchmens[activeHenchmenIndex]?.trafficLimit
    );
    setNamechanged(
      remoteData?.data?.henchmens[activeHenchmenIndex]?.description
    );
  }, [activeHenchmen, activeHenchmenIndex]);

  const getTestedDays = () => {
    const endDate = new Date(remoteData?.data?.endDate);
    const diffInMs = Math.abs(today - endDate);
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
    return diffInDays;
  };

  const handleClose = () => {
    setIsRemoteUsersHidden(true);
    setTimeout(setDoneMessageShow, 300, (state) => (state = false));
  };

  const handleNameChanged = (e) => {
    setNamechanged(e.target.value);
  };

  const handleTrafficChanged = (e) => {
    const hencmensLimit = remoteData?.data?.henchmens.reduce(
      (acc, henchman) => {
        if (henchman.trafficLimit !== 2000) {
          return acc + henchman.trafficLimit;
        }
        return acc;
      },
      0
    );

    const leftGB = remoteData?.data?.trafficTotal - hencmensLimit;

    if (e.target.value > leftGB) {
      setIsTrafficLimited(true);
      setTimeout(() => {
        setIsTrafficLimited(false);
      }, 3000);
    } else {
      setTrafficChanged(e.target.value);
    }
  };

  const handleCreateUser = () => {
    const isAvailableHechmens = henchmens.some(
      (item) => item.deletedUser === 1
    );

    if (isAvailableHechmens) {
      // setActiveHenchmen(henchmens[henchmens.findIndex((item) => item.deletedUser === 1)].id);
      setHenchmensOptions(
        henchmens[henchmens.findIndex((item) => item.deletedUser === 1)].id,
        { deletedUser: 0 }
      );
      setDoneMessageShow(true);
      setDoneMessage(
        translations.ru.remote.remoteDoneCreated1 +
          translations.ru.remote.remoteDoneCreated2
      );
    } else {
      setDoneMessageShow(true);
      setDoneMessage(translations.ru.remote.remoteDoneNotCreated);
    }
  };

  const handleNameTrafficChanged = () => {
    if (currentUser.owner === 0) {
      setDirection(true);
      navigate("/tariffes/own");
    } else {
      setHenchmensOptions(activeHenchmen, {
        description: nameChanged,
        trafficLimit:
          trafficChanged === "сколько хочется" ? 2000 : trafficChanged,
      });
      setIsSettingsChanged(true);
      setTimeout(() => {
        setIsSettingsChanged(false);
      }, 3000);
    }
  };

  function getUsedGb() {
    if (tariffType === "ownme") {
      return 500 - remoteData.data.trafficTotal;
    }

    if (tariffType === "ownfam") {
      return 1000 - remoteData.data.trafficTotal;
    }

    if (tariffType === "owncom") {
      return 2000 - remoteData.data.trafficTotal;
    }

    if (remoteData?.data?.tariff?.includes("_test")) {
      return 50 - remoteData?.data?.trafficTotal;
    }
  }

  function getStartDate() {
    const months = parseInt(
      remoteData?.data?.tariff?.replace(/[a-zA-Z_]/g, "")
    );
    const endDate = new Date(remoteData?.data?.endDate);
    return parseTimestamp(endDate.setMonth(endDate.getMonth() - months));
  }

  function setHenchmensOptions(henchmenId, opts) {
    const index = henchmens.findIndex((henchman) => henchman.id === henchmenId);

    if (index === -1) {
      return henchmens;
    }

    const updatedHenchmens = [
      ...henchmens.slice(0, index),
      {
        ...henchmens[index],
        ...opts,
      },
      ...henchmens.slice(index + 1),
    ];

    const updatedHenchmensId = updatedHenchmens.map((user) => ({
      ...user,
      ownerId: currentUser.userId,
    }));
    setRemoteData({
      data: {
        tariff: remoteData?.data?.tariff,
        endDate: remoteData?.data?.endDate,
        linkDate: remoteData?.data?.linkDate,
        nextGB: remoteData?.data?.nextGB,
        trafficTotal: remoteData?.data?.trafficTotal,
        henchmens: updatedHenchmens,
      },
    });

    setRemoteOpts(currentUser.userId, activeHenchmen, updatedHenchmensId);
  }

  const renderusersList = (arr) => {
    return arr?.map((item, i) => {
      return (
        <div className="remote__users-container" key={i}>
          <li
            className={`remote__users-item ${
              activeHenchmenIndex === i && "active"
            } ${item.deletedUser === 1 && "hidden"}`}
            onClick={() => {
              setActiveHenchmenIndex(i);
              setActiveHenchmen(item.id);
              setNamechanged(item.description);
            }}
            key={i}
          >
            <input
              type="text"
              value={activeHenchmenIndex === i ? nameChanged : item.description}
              onChange={handleNameChanged}
              className={`remote__users-item-name  ${
                activeHenchmenIndex === i && "active"
              }`}
              onBlur={() =>
                setHenchmensOptions(activeHenchmen, {
                  description: nameChanged,
                  trafficLimit: trafficChanged,
                })
              }
            />
            <div className="remote__users-item-got">
              <input
                type={
                  item.remove === 1 || item.trafficLimit === 2000
                    ? "text"
                    : "number"
                }
                min={0}
                onBlur={() =>
                  setHenchmensOptions(activeHenchmen, {
                    description: nameChanged,
                    trafficLimit: trafficChanged,
                  })
                }
                value={
                  item.remove === 1
                    ? "ограничен"
                    : item.trafficLimit === 2000
                    ? "сколько хочется"
                    : activeHenchmenIndex === i
                    ? trafficChanged
                    : item.trafficLimit
                }
                onChange={handleTrafficChanged}
                className={`remote__users-item-got-input ${
                  (item.remove === 1 || item.trafficLimit === 2000) && "removed"
                } ${activeHenchmenIndex === i && "active"}`}
              />
              {/* <span className={`remote__users-item-got__gb ${item.remove === 1 && 'hidden'}`}>{translations.ru.textTips.gb}</span> */}
            </div>
            <span className="remote__users-item-left">
              {Math.round(item.traffic)}
            </span>
            <div
              className={`remote__users-item-more ${
                activeHenchmenIndex === i && "active"
              }`}
              onClick={() => {
                setIsRemoteUsersHidden(false);
                setActiveHenchmen(item.id);
              }}
            >
              <div></div>
              <div></div>
              <div></div>
            </div>
          </li>
        </div>
      );
    });
  };

  const usersList = renderusersList(remoteData?.data?.henchmens);

  const renderRemoteFields = () => {
    return (
      <>
        <div
          className={`${!isShowSpecialFieldsLayout && "remote__container"} ${
            isRemoteInProgress && "inprogress"
          } ${isShowSpecialFieldsLayout && "remote__container_special"}`}
        >
          <BackButton
            path="/"
            text={translations.ru.appButton.mainMenu}
            currentClass={`${isRemoteInProgress ? "blue" : "white"}`}
            title={translations.ru.textTips.remoteControl}
          />
          {isRemoteInProgress ? (
            <>
              <div className="remote-inprogress__image-container">
                <img
                  className="remote-inprogress__image"
                  src={introImg}
                  alt="happy face"
                />
              </div>
              <h1 className="remote-inprogress__title">
                {translations.ru.remote.remoteInprogressTitle1} <br />
                <span className="remote-inprogress__title_colored">
                  {" "}
                  {translations.ru.remote.remoteInprogressTitle2}
                </span>
              </h1>
              <p className="remote-inprogress__text">
                {translations.ru.remote.remoteInprogressText}
              </p>
              <div className="remote-inprogress__button-container">
                <AppButton
                  text={translations.ru.appButton.mainMenu}
                  currentClass="app-button border-blue secondary blue"
                  handler={() => navigate("/")}
                />
                <AppButton
                  currentClass="app-button primary white bg-blue margin-top"
                  text={translations.ru.appButton.gotIt}
                  handler={() => tg.close()}
                />
              </div>
            </>
          ) : (
            <>
              <div
                className={`remote__speed ${isDisabled && "no-own"} ${
                  isTrafficLimited && "limited-error"
                }`}
              >
                Гарантированный канал для скорости 1 Гигабит/сек
              </div>
              <div className={`remote__info`}>
                <div className="remote__info-gb">
                  <div className="remote__info-gb-left">
                    <span
                      className={`remote__info-title ${
                        isDisabled && "disabled"
                      }`}
                    >
                      {isDisabled
                        ? "300"
                        : Math.round(remoteData?.data?.trafficTotal)}{" "}
                      {translations.ru.textTips.gb}
                    </span>
                    <span
                      className={`remote__info-subtitle ${
                        isDisabled && "disabled"
                      }`}
                    >
                      {translations.ru.remote.remoteLeft}
                    </span>
                  </div>
                  <div className="remote__info-gb-used">
                    <span className="remote__info-title">
                      {isDisabled ? "200" : Math.round(getUsedGb())}{" "}
                      {translations.ru.textTips.gb}
                    </span>
                    <span className="remote__info-subtitle">
                      {translations.ru.remote.remoteUsed}
                    </span>
                  </div>
                </div>

                <div className="remote__info-users">
                  <span className="remote__info-title-big">
                    {isDisabled ? "500" : getOwnTariffTypeGb()}
                  </span>
                  <span className="remote__info-subtitle">
                    {translations.ru.remote.remoteTotal1}
                    <br />
                    {translations.ru.remote.remoteTotal3}
                    {isDisabled
                      ? "8.4.2023"
                      : parseTimestamp(remoteData?.data?.linkDate)}
                    {translations.ru.remote.remoteTotal4}
                    {isDisabled
                      ? "5.5.2023"
                      : remoteData?.data?.nextGB
                      ? parseTimestamp(remoteData?.data?.nextGB)
                      : parseTimestamp(remoteData?.data?.endDate)}
                    <br />
                    {translations.ru.remote.remoteTotal5}
                    {isDisabled ? "1-3" : getOwnTariffPeople()}
                    {translations.ru.remote.remoteTotal6}
                  </span>
                </div>
              </div>

              <div className={`remote__users ${isDisabled && "margin-bottom"}`}>
                <div className="remote__users-titles">
                  <span className="remote__users-title">
                    <span>{translations.ru.remote.remoteUsers1Title1}</span>
                    <span>{translations.ru.remote.remoteUsers1Title2}</span>
                  </span>
                  <span className="remote__users-title">
                    <span>{translations.ru.remote.remoteUsers2Title1}</span>
                    <span>{translations.ru.remote.remoteUsers2Title2}</span>
                  </span>
                  <span className="remote__users-title">
                    <span>{translations.ru.remote.remoteUsers3Title1}</span>
                    <span>{translations.ru.remote.remoteUsers3Title2}</span>
                  </span>
                </div>
                <ul className="remote__users-list">
                  {isDisabled ? (
                    <>
                      <li className={`remote__users-item`}>
                        <input
                          type="text"
                          value={"Пользователь"}
                          className={`remote__users-item-name disabled`}
                        />
                        <div className="remote__users-item-got">
                          <input
                            type="number"
                            value={10}
                            className={`remote__users-item-got-input disabled`}
                          />
                        </div>
                        <span className="remote__users-item-left">{5}</span>
                        <div className={`remote__users-item-more`}>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </li>
                      <li className={`remote__users-item`}>
                        <input
                          type="text"
                          value={"Пользователь"}
                          className={`remote__users-item-name disabled`}
                        />
                        <div className="remote__users-item-got">
                          <input
                            type="number"
                            value={10}
                            className={`remote__users-item-got-input disabled`}
                          />
                        </div>
                        <span className="remote__users-item-left">{5}</span>
                        <div className={`remote__users-item-more`}>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </li>
                      <li className={`remote__users-item`}>
                        <input
                          type="text"
                          value={"Пользователь"}
                          className={`remote__users-item-name disabled`}
                        />
                        <div className="remote__users-item-got">
                          <input
                            type="number"
                            value={10}
                            className={`remote__users-item-got-input disabled`}
                          />
                        </div>
                        <span className="remote__users-item-left">{5}</span>
                        <div className={`remote__users-item-more`}>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </li>
                    </>
                  ) : (
                    usersList
                  )}
                </ul>
              </div>

              <RemotePopup
                isHidden={isRemoteUsersHidden}
                setIsHidden={setIsRemoteUsersHidden}
                setDoneShow={setDoneMessageShow}
              >
                {doneMessageShow ? (
                  <>
                    <span className="remote__popup-message">{doneMessage}</span>
                    <AppButton
                      currentClass="app-button orange bg-white margin-top"
                      text="OK"
                      handler={handleClose}
                    />
                  </>
                ) : (
                  <>
                    <AppButton
                      handler={() => {
                        getHenchmenLink(activeHenchmen);
                        setDoneMessageShow(true);
                        setDoneMessage(
                          translations.ru.remote.remoteDoneLink1 +
                            henchmens.find(
                              (hechmen) => hechmen.id === activeHenchmen
                            ).description +
                            translations.ru.remote.remoteDoneLink2
                        );
                      }}
                      text={translations.ru.remote.remoteBtnSendLink}
                      currentClass="app-button border-white bg-orange white small-text"
                    />
                    <AppButton
                      handler={() => {
                        setHenchmensOptions(activeHenchmen, {
                          trafficLimit: 2000,
                        });
                        setDoneMessageShow(true);
                        setDoneMessage(
                          translations.ru.remote.remoteDoneLet1 +
                            henchmens.find(
                              (hechmen) => hechmen.id === activeHenchmen
                            ).description +
                            translations.ru.remote.remoteDoneLet2
                        );
                      }}
                      text={translations.ru.remote.remoteBtnLet}
                      currentClass="app-button border-white bg-orange white small-text margin-top"
                    />
                    <AppButton
                      handler={() => {
                        setHenchmensOptions(
                          activeHenchmen,
                          henchmens.find(
                            (hechmen) => hechmen.id === activeHenchmen
                          ).remove === 1
                            ? { remove: 0 }
                            : { remove: 1 }
                        );
                        setDoneMessageShow(true);
                        setDoneMessage(
                          remoteData?.data?.henchmens?.find(
                            (hechmen) => hechmen.id === activeHenchmen
                          )?.remove === 1
                            ? translations.ru.remote.remoteDoneStart1 +
                                remoteData?.data?.henchmens?.find(
                                  (hechmen) => hechmen.id === activeHenchmen
                                )?.description +
                                translations.ru.remote.remoteDoneStart2
                            : translations.ru.remote.remoteDoneStop1 +
                                remoteData?.data?.henchmens?.find(
                                  (hechmen) => hechmen.id === activeHenchmen
                                )?.description +
                                translations.ru.remote.remoteDoneStop2
                        );
                      }}
                      text={
                        remoteData?.data?.henchmens?.find(
                          (hechmen) => hechmen.id === activeHenchmen
                        )?.remove === 1
                          ? translations.ru.remote.remoteBtnStart
                          : translations.ru.remote.remoteBtnStop
                      }
                      currentClass="app-button border-white bg-orange white small-text margin-top"
                    />
                    <AppButton
                      handler={() => {
                        setHenchmensOptions(activeHenchmen, { deletedUser: 1 });
                        setDoneMessageShow(true);
                        setDoneMessage(
                          translations.ru.remote.remoteDoneDelete1 +
                            remoteData?.data?.henchmens.find(
                              (hechmen) => hechmen.id === activeHenchmen
                            ).description +
                            translations.ru.remote.remoteDoneDelete2
                        );
                      }}
                      text={translations.ru.remote.remoteBtnDelete}
                      currentClass="app-button border-white bg-orange white small-text margin-top"
                    />
                    <AppButton
                      handler={() => handleCreateUser()}
                      text={translations.ru.remote.remoteBtnAddUser}
                      currentClass="app-button border-white bg-orange white small-text margin-top"
                    />

                    <div className="remote__popup-info">
                      {getMonthOrTest ? (
                        <>
                          {translations.ru.remote.remoteInfoPopup1 +
                            getOwnTariffPeople() +
                            translations.ru.remote.remoteInfoPopup2 +
                            translations.ru.remote.remoteInfoPopup3}
                        </>
                      ) : (
                        <>
                          {translations.ru.remote.remoteInfoPopup1 +
                            getOwnTariffPeople() +
                            translations.ru.remote.remoteInfoPopup2 +
                            translations.ru.remote.remoteInfoPopup4 +
                            parseTimestamp(remoteData?.data?.endDate) +
                            translations.ru.remote.remoteInfoPopup5 +
                            getOwnTariffTypeGb() +
                            translations.ru.remote.remoteInfoPopup6}
                        </>
                      )}
                    </div>
                  </>
                )}
              </RemotePopup>

              {isDisabled ? null : (
                <div className="remote__info-connection">
                  {translations.ru.tariffOwn.ownInfoConnection}
                </div>
              )}
            </>
          )}
        </div>
        {isDisabled && (
          <div className="remote__future-button">
            <AppButton
              text={translations.ru.textTips.inTheFuture}
              currentClass="app-button bg-grey white remote-future margin-top"
              handler={() => {
                setDirection(true);
                navigate("/remote-to-be-added");
              }}
            />
          </div>
        )}
        <div className="remote__settings-button">
          {isSettingsChanged && (
            <div className="remote__settings-button__popup">
              <span className="remote__settings-button__popup-text">
                {translations.ru.remote.remoteSettingsChanged}
              </span>
            </div>
          )}
          <AppButton
            text={
              isDisabled
                ? translations.ru.remote.remoteNoOwnBtn
                : translations.ru.remote.remoteUseOpts
            }
            currentClass="app-button bg-white orange margin-top"
            handler={handleNameTrafficChanged}
          />
        </div>
      </>
    );
  };

  const renderSpecialOfferFields = () => {
    return (
      <div>
        <div className="main__toggle-page orange-page">
          <div className="orange-page__top">
            <div>x2</div>
            <div>
              <span>
                <img className="star" src={Star} alt="Star" />
              </span>
              бесплатного трафика <br />
              на старте
            </div>
            <div>
              <span>
                <img className="star" src={Star} alt="Star" />{" "}
                <img className="star" src={Star} alt="Star" />
              </span>
              на все <br />
              первые покупки новичкам
            </div>
            <div>
              <span>
                <img className="star" src={Star} alt="Star" />
                <img className="star" src={Star} alt="Star" />
                <img className="star" src={Star} alt="Star" />
              </span>
              всем-всем каждый раз, когда 10 новичков
            </div>
          </div>

          <div className="orange-page__mid">
            <div
              onClick={() => toggleDetailsVisibility(0)}
              className={`mid__item ${
                areDetailsVisible[0] && "mid__item_visible"
              }`}
            >
              <div className="mid__item_top_block">
                <h2 className="mid__title n-counter">N</h2>
                <p className="mid__text">осталось пригласить</p>
              </div>

              <button className="mid__btn">
                <img src={ArrowDown} />
              </button>
              {areDetailsVisible[0] && (
                <>
                  <p className="mid__small-text">
                    Через{" "}
                    <b>
                      <span className="n-counter">N</span>
                    </b>{" "}
                    мы удвоим покупки всем. Всего уже{" "}
                    <b>
                      <span className="l-counter">L</span>
                    </b>{" "}
                    пользуются robo, среди них
                    <b>
                      <span className="k-counter">K</span>
                    </b>{" "}
                    совершили хотя бы одну покупку.
                  </p>
                  <button className="mid__btn">
                    <img className="arrow_up" src={ArrowUp} />
                  </button>
                </>
              )}
            </div>

            <div
              onClick={() => toggleDetailsVisibility(1)}
              className={`mid__item ${
                areDetailsVisible[1] && "mid__item_visible"
              }`}
            >
              <div className="mid__item_top_block">
                <h2 className="mid__title">12 000 ₽</h2>
                <p className="mid__text">подарим денег</p>
              </div>
              <button className="mid__btn">
                <img src={ArrowDown} />
              </button>
              {areDetailsVisible[1] && (
                <>
                  <p className="mid__small-text">
                    Через <span className="n-counter">N</span> новичков, кто
                    совершит оплаты — столько денег мы подарим вашей команде.
                    Подарили уже <span className="t-counter">T</span>
                  </p>
                  <button className="mid__btn">
                    <img className="arrow_up" src={ArrowUp} />
                  </button>
                </>
              )}
            </div>
          </div>

          <div className="orange-page__bottom">
            <button
              onClick={handlePressCopy}
              className="bot__btn bot__btn_white"
            >{`${
              isCopied ? "Ссылка скопирована!" : "Скопировать ссылку"
            }`}</button>
            <button onClick={handlePressShare} className="bot__btn">
              Скинуть реферальную ссылку
            </button>
          </div>
        </div>
        <div className="main__toggle-page grey-page"></div>
      </div>
    );
  };

  const renderSpecialLayout = () => {
    return (
      <>
        <BackButton
          path="/"
          text={translations[language].appButton.myVpn}
          currentClass={`orange`}
          title={translations[language].textTips.remoteFull}
        />
        <div className="remote_main_container">
          <img
            className={`main_orange_bg ${
              isSpecialOfferActive && "main_active_bg"
            }`}
            src={isDisabled ? OrangeBg : OrangeSpecialBg}
          />
          <img
            className={`main_grey_bg ${
              !isSpecialOfferActive && "main_active_bg"
            }`}
            src={isDisabled ? GreyBg : OrangeTeamBg}
          />
          <div className="main__toggle-btns">
            <button
              onClick={() => setIsSpecialOfferActive(true)}
              className="main__toggle-btn"
            >
              Special offer <br />
              для команды{" "}
              <img
                className="star-struck-img"
                src={StarStruck}
                alt="Star struck"
              />
            </button>
            <button
              onClick={() => setIsSpecialOfferActive(false)}
              className="main__toggle-btn"
            >
              Early Bird: <br />
              тариф TEAM
            </button>
          </div>
          <div className="main__pages">
            {isSpecialOfferActive
              ? renderSpecialOfferFields()
              : renderRemoteFields()}
          </div>
        </div>
      </>
    );
  };

  return (
    <motion.section
      className={`remote ${isDisabled && "disabled"} ${
        isRemoteInProgress && "inprogress"
      } ${isShowSpecialFieldsLayout && "special-fields-layout-section"}`}
      initial={direction ? "fromLeft" : "fromRight"}
      animate={{
        x: 0,
        opacity: 1,
        transition: { duration: 0.2, delay: 0.2 },
      }}
      exit={direction ? "exitToRight" : "exitToLeft"}
      variants={directionVariants}
    >
      {isShowSpecialFieldsLayout ? renderSpecialLayout() : renderRemoteFields()}
      <Popup
        title={translations.ru.remote.remoteInstruction}
        isHidden={isRemotePopupHidden}
        handleHide={setIsRemotePopupHidden}
        currentClass={`popup-remote ${isDisabled && "disabled"}`}
      >
        {
          <>
            <div className="tariffes__popup-overlay" />
            <div className="tariffes__popup-container">
              <p className="referral__popup-text">
                {translations.ru.remote.remotePopupText1}
              </p>
              <p className="referral__popup-text">
                {translations.ru.remote.remotePopupText2}
              </p>
              <p className="referral__popup-text">
                {translations.ru.remote.remotePopupText3}
              </p>
              <p className="referral__popup-text">
                {translations.ru.remote.remotePopupText4}
              </p>
              <p className="referral__popup-text">
                {translations.ru.remote.remotePopupText5}
              </p>
              <p className="referral__popup-text">
                {translations.ru.remote.remotePopupText6}
              </p>
              <p className="referral__popup-text">
                {translations.ru.remote.remotePopupText7}
              </p>
              <p className="referral__popup-text">
                {translations.ru.remote.remotePopupText8}
              </p>
              <p className="referral__popup-text">
                {translations.ru.remote.remotePopupText9}
              </p>
              <p className="referral__popup-text">
                {translations.ru.remote.remotePopupText10}
              </p>
              <p className="referral__popup-text">
                {translations.ru.remote.remotePopupText11}
              </p>
              <p className="referral__popup-text">
                {translations.ru.remote.remotePopupText12}
              </p>
              <p className="referral__popup-text">
                {translations.ru.remote.remotePopupText13}
              </p>
              <p className="referral__popup-text">
                {translations.ru.remote.remotePopupText14}
              </p>
            </div>
            <AppButton
              text={translations.ru.appButton.gotIt}
              currentClass={`margin-top bg-orange primary white`}
              handler={() => setIsRemotePopupHidden(true)}
            />
          </>
        }
      </Popup>
    </motion.section>
  );
}

export default Remote;
