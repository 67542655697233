import React from "react";
import "./TariffesList.css";
import CurrentTariffWidget from "../CurrentTariffWidget/CurrentTariffWidget";
import FormLabel from "../FormLabel/FormLabel";
import AppButton from "../AppButton/AppButton";
import Popup from "../Popup/Popup";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setDirection } from "../../redux/actions/actions";
import PropTypes from "prop-types";
import { translations } from "../../utils/translations/translations";
import { parseTimestamp } from "../../utils/helpers";

function TariffesList({ value, setValue, error, handleError }) {
  const [isTariffPopupHidden, setIsTariffPopupHidden] = React.useState(true);
  const [active, setActive] = React.useState();
  const language = useSelector((state) => state.language);
  const navigate = useNavigate();
  const prices = useSelector((state) => state.prices);
  const remoteData = useSelector((state) => state.remoteData);
  const isNewCorp = !!remoteData?.isCorpTariff;
  const currentUser = useSelector((state) => state.currentUser);
  const isNeverOwn = currentUser.owner === 0 && currentUser.own_tested === 0;
  const isTestOwnTariff =
    currentUser.own_tested === 1 &&
    (remoteData?.data?.tariff === "ownme_test" ||
      remoteData?.data?.tariff === "ownfam_test" ||
      remoteData?.data?.tariff === "owncom_test");
  const isOwnTariff =
    currentUser.owner === 1 &&
    remoteData?.data?.tariff !== "ownme_test" &&
    remoteData?.data?.tariff !== "ownfam_test" &&
    remoteData?.data?.tariff !== "owncom_test";
  const tariffType = remoteData?.data?.tariff?.replace(/[^a-zA-Z]/g, "");
  const today = new Date();

  function handleChooseClick() {
    if (value.length) {
      setDirection(true);
      navigate(`/tariffes/${value}`);
    } else {
      handleError(translations[language].tariffes.errorTariff);
    }
  }

  const getOwnTariffTypeGb = () => {
    if (tariffType === "ownme") {
      return 500;
    }
    if (tariffType === "ownfam") {
      return 1000;
    }

    if (tariffType === "owncom") {
      return 2000;
    }
    if (remoteData?.data?.tariff?.includes("_test")) {
      return 50;
    }
  };

  const getTestedDays = () => {
    const endDate = new Date(remoteData?.data?.endDate);
    const diffInMs = Math.abs(today - endDate);
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
    return diffInDays;
  };
  return (
    <>
      {/* {currentUser.owner === 1  
        ?  (isNeverOwn 
              ? (<div className='tariffes-template__speed'>
                {translations[language].tariffOwn.ownSpeed1}
                <br />
                {translations[language].tariffOwn.ownSpeed2}
                </div>)
            : (isOwnTariff 
              ? (<div className='tariffes-template__speed'>
                    {translations[language].tariffOwn.ownWidget1}
                    {','}
                    {remoteData?.data?.endDate > today ? (translations[language].tariffOwn.ownWidget5 + parseTimestamp(remoteData?.data?.endDate)) : null}
                    <br />
                    {remoteData?.data?.endDate > today 
                    ?
                      (remoteData?.data?.trafficTotal > 0 
                      ? (translations[language].tariffOwn.ownWidget7 
                      + (remoteData?.data?.nextGB ? parseTimestamp(remoteData?.data?.nextGB) : parseTimestamp(remoteData?.data?.endDate)) 
                      + translations[language].tariffOwn.ownWidget8 
                      + remoteData?.data?.trafficTotal
                      + translations[language].textTips.gb)
                      : (translations[language].tariffOwn.ownWidget9 
                        + getOwnTariffTypeGb()
                        + translations[language].textTips.gb)
                        + ' '
                        + (remoteData?.data?.nextGB ? parseTimestamp(remoteData?.data?.nextGB) : parseTimestamp(remoteData?.data?.endDate)))
                    : null}
                  </div>)
              : (
                isTestOwnTariff && remoteData?.data?.endDate > today 
                ? (<div className='tariffes-template__speed'>
                    {translations[language].tariffOwn.ownWidget1}
                    <br />
                    {translations[language].tariffOwn.ownWidget2}
                    {getTestedDays()}
                    {translations[language].tariffOwn.ownWidget10}
                  </div>
                )
                :(
                  <div className='tariffes-template__speed'>
                    {translations[language].tariffOwn.ownWidget1}
                    <br />
                    {translations[language].tariffOwn.ownWidget4}
                  </div>
                  )
              )
              ))
        : <CurrentTariffWidget isTariffesList={true} />
      } */}
      <div className="tariffes-list">
        <FormLabel
          elementValue="fit"
          name="tariff"
          handler={(data) => {
            setValue(data);
            setActive("fit");
          }}
          currentClass="tariff-item-fit"
          title="FIT"
          text={translations[language].tariffesList.tariffesListFit}
          valueMain={
            translations[language].textTips.from +
            prices.Fix_3[0] +
            translations[language].textTips.currency
          }
          isDiscounted={false}
        />
        <FormLabel
          elementValue="nolimit"
          name="tariff"
          handler={(data) => {
            setValue(data);
            setActive("nolimit");
          }}
          currentClass="tariff-item-nolimit"
          title="NOLIMIT"
          text={translations[language].tariffesList.tariffesListNolimit}
          valueMain={
            translations[language].textTips.from +
            prices.Nolimit_24[0] +
            translations[language].textTips.currency
          }
          isDiscounted={false}
          isProfit={active === "nolimit" ? true : false}
          profit={translations[language].tariffesList.tariffesListGuaratee1}
          tariffesListClass={"nolimit-list"}
        />
        <FormLabel
          elementValue="own"
          name="tariff"
          handler={(data) => {
            setValue(data);
            setActive("own");
          }}
          currentClass="tariff-item-own-list"
          title={isNewCorp ? "TEAM" : "OWN"}
          text={
            // <>
            //   {translations[language].tariffesList.tariffesListOwn1}
            //   <br />
            //   {translations[language].tariffesList.tariffesListOwn2}
            // </>
            translations[language].tariffesList.tariffesListOwn1
          }
          valueMain={
            translations[language].textTips.from +
            prices.ownme_12[0] +
            translations[language].textTips.currency
          }
          isDiscounted={false}
          isProfit={active === "own" ? true : false}
          tariffesListClass={"own-list"}
          profit={translations[language].tariffesList.tariffesListGuaratee2}
        />
        <Popup
          title={translations[language].textTips.termsAndConditions}
          currentClass="popup-tariff"
          isHidden={isTariffPopupHidden}
          handleHide={setIsTariffPopupHidden}
        >
          {
            <>
              <div className="tariffes__popup-overlay" />
              <div className="tariffes__popup-container">
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA1}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA2}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA3}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA4}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA5}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA6}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA7}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA8}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA9}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA10}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA11}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA12}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA13}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA14}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA15}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA16}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA17}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA18}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA19}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA20}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA21}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA22}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA23}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA24}
                </p>
                <p className="tariffes__popup-text">
                  {translations[language].tariffesList.tariffesListPopupA25}
                </p>
              </div>
              <AppButton
                text={translations[language].appButton.gotIt}
                currentClass="margin-top bg-blue primary white"
                handler={() => setIsTariffPopupHidden(true)}
              />
            </>
          }
        </Popup>
      </div>
      {/* <div className='tariffes__content-secondary'>
        <span className='tariffes__error'>{error}</span>
        <div className='tariffes__button-box'>
          <AppButton
            text={translations[language].appButton.choose}
            currentClass='primary white bg-blue'
            handler={handleChooseClick}
          />
        </div>
        <span className='tariffes__tips'>
          {translations[language].tariffesList.tariffesListTips}
        </span>
      </div> */}
    </>
  );
}
TariffesList.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  handleError: PropTypes.func.isRequired,
};
export default TariffesList;
